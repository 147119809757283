import React from 'react'
import CanceledGif from "../logoimg/canceled.gif"
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import "./cssPayment/payment.css";



const Canceled = () => {
    const navigate=useNavigate()

    // let {token}=useParams()

    // let {tokenX}=useSelector(state=>state.Data)

    

    // token=tokenX

    const goToHome=()=>{
        navigate("/dashboard")
    }
    return (


        <div className='approved_page_container'>
            <h2> Ödəniş ləğv edildi!</h2>
            <img src={CanceledGif} alt="canceled icon" />
            <button onClick={goToHome}>Əsas səhifəyə qayıt</button>
        </div>
    )
}

export default Canceled