import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getExams } from '../../actions/MainAction';
import axios from 'axios';
import { baseUrl } from '../../MAIN_API';
import { useNavigate } from 'react-router-dom';
import { setRefreshed } from '../../redux/MainReducer';
import Swal from 'sweetalert2';

const ExamsStartModal = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { examTypeObj, examDuration, refreshed } = useSelector(state => state.Data);

    useEffect(() => {
        dispatch(getExams(examTypeObj?.slug));
    }, [dispatch]);

    const [variantBgArrays, setVariantBgArrays] = useState({});
    const [allAnswerVariants, setAllAnswerVariants] = useState([]);

    // **SEÇİLMİŞ CAVABLARI LOCALSTORAGE-DƏN OXU**
    useEffect(() => {
        const savedAnswers = localStorage.getItem("selectedAnswers");
        if (savedAnswers) {
            const parsedAnswers = JSON.parse(savedAnswers);
            setVariantBgArrays(parsedAnswers.variantBgArrays);
            setAllAnswerVariants(parsedAnswers.allAnswerVariants);
        }
    }, []);

    const getRemainingTime = () => {
        const storedStartTime = localStorage.getItem("examStartTime");
        let startTime = storedStartTime ? parseInt(storedStartTime) : null;
        if (!startTime) {
            startTime = Date.now();
            localStorage.setItem("examStartTime", startTime.toString());
        }
        const elapsed = Date.now() - startTime;
        return Math.max(examDuration * 60 - Math.floor(elapsed / 1000), 0);
    };

    const [remainingTime, setRemainingTime] = useState(getRemainingTime());

    useEffect(() => {
        const timer = setInterval(() => {
            setRemainingTime(prevTime => {
                const newTime = getRemainingTime();
                if (newTime === 0) {
                    clearInterval(timer);
                    examFinishedHandle();
                }
                return newTime;
            });
        }, 1000);
        return () => clearInterval(timer);
    }, []);

    const examAnswerVariantHandle = (examIndex, questionIndex, answerIndex, answerData, question) => {
        const examVariantBgArray = variantBgArrays[examIndex] || {};
        examVariantBgArray[questionIndex] = answerIndex;

        const updatedVariantBgArrays = {
            ...variantBgArrays,
            [examIndex]: examVariantBgArray
        };

        setVariantBgArrays(updatedVariantBgArrays);

        const newAllAnswerVariants = allAnswerVariants.filter(item => item.question !== question.id);
        const updatedAllAnswerVariants = [
            ...newAllAnswerVariants,
            {
                question: question.id,
                selected_variant: answerData.answer_variant,
                given_answer: null
            }
        ];

        setAllAnswerVariants(updatedAllAnswerVariants);

        // **SEÇİLMİŞ CAVABLARI LOCALSTORAGE-DƏ SAXLA**
        localStorage.setItem("selectedAnswers", JSON.stringify({ variantBgArrays: updatedVariantBgArrays, allAnswerVariants: updatedAllAnswerVariants }));
    };

    const hours = Math.floor(remainingTime / 3600);
    const minutes = Math.floor((remainingTime % 3600) / 60);
    const seconds = remainingTime % 60;

    const examFinishedHandle = () => {
        const data = {
            student_answers: allAnswerVariants
        };

        axios({
            url: `${baseUrl}exam/student-exam/${examTypeObj?.id}/result/`,
            method: "POST",
            headers: {
                Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN_EXAM')}`
            },
            data
        }).then(resp => {
            if (resp.status === 201) {
                Swal.fire({
                    title: "İmtahan bitdi!",
                    icon: "success",
                    confirmButtonText: "OK",
                    width: "400px"
                }).then(function () {
                    localStorage.removeItem("examStartTime");
                    localStorage.removeItem("selectedAnswers");
                    navigate(`/exams`);
                    if (!refreshed) {
                        dispatch(setRefreshed());
                        window.location.reload();
                    }
                });
            }
        }).catch(err => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...'
            }).then(function () {
                navigate(`/exams`);
                if (!refreshed) {
                    dispatch(setRefreshed());
                    window.location.reload();
                }
            });
        });
    };

    return (
        <div className="modal_container">
            <div className="branch_create_modal_card exams_modal_card">
                <div className="branch_update_modal_container">
                    <div className="exam_timer">
                        {`${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`}
                    </div>
                    {examTypeObj?.exam?.exams?.map((exam, examIndex) => (
                        <div key={examIndex} className='exams_questions_container'>
                            <h2>{exam.exam_name}</h2>
                            {exam.questions.map((question, questionIndex) => (
                                <div key={questionIndex} className='exams_questions_div'>
                                    <div className='exams_questions'>
                                        <span>{question.number}.</span>
                                        <p>{question.question}</p>
                                        {question.question_image && (
                                            <div className='exams_questions_image'>
                                                <img src={question.question_image} alt="" />
                                            </div>
                                        )}
                                    </div>
                                    <div className='exams_answers_container'>
                                        {question.answers.map((answerData, answerIndex) => (
                                            <div key={answerIndex}>
                                                <span
                                                    style={{
                                                        backgroundColor: variantBgArrays[examIndex]?.[questionIndex] === answerIndex ? "orange" : "white",
                                                    }}
                                                    onClick={() => examAnswerVariantHandle(examIndex, questionIndex, answerIndex, answerData, question)}>
                                                    {answerData.answer_variant})
                                                </span>
                                                {answerData.answer_image === null ? (
                                                    <p>{answerData.answer}</p>
                                                ) : (
                                                    <img src={answerData.answer_image} alt="" />
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))}
                    <button onClick={examFinishedHandle} className='exams_complated_button'>İmtahanı bitir</button>
                </div>
            </div>
        </div>
    );
}

export default ExamsStartModal;
