import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { examsDateUpdateFunc, examsTimeUpdateFunc, modalCloseFunc, modalOverlayFunc, setRefreshed } from '../../redux/MainReducer'
import Swal from 'sweetalert2'
import axios from 'axios'
import { baseUrl } from '../../MAIN_API'

const ExamsDateTimeUpdateModal = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { examsUpdateObj,examsTimeUpdateValue,examsDateUpdateValue,refreshed } = useSelector(state => state.Data)

    const [reversedDate, setReversedDate] = useState("");

    useEffect(() => {
        const parts = examsDateUpdateValue?.split("-");
        if (parts?.length === 3) {
            setReversedDate(`${parts[2]}-${parts[1]}-${parts[0]}`);
        }
    }, [examsDateUpdateValue]);



    console.log(examsUpdateObj);

    // console.log(examsObj);

    const examsDateTimeUpdateHandle=()=>{
        const data={
            exam_time: examsTimeUpdateValue,
            exam_date: reversedDate
        }

        axios({
            method: "PUT",
            url: `${baseUrl}exam/update-exam/${examsUpdateObj.exam_id}/`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN_EXAM')}`
            },
            data
        }).then(resp => {
            console.log(resp);
            if (resp.status === 200) {

                Swal.fire({
                    title: "Tarix və vaxt dəyişdirildi",
                    icon: "success",
                    confirmButtonText: "OK",
                    width: "400px"
                }).then(function () {
                    navigate(`/dashboard`)
                    if (!refreshed) {
                        dispatch(setRefreshed())
                        window.location.reload();
                    }



                });
            }
        }).catch(err => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: '',

            }).then(function () {

                navigate(`/dashboard`)
                if (!refreshed) {
                    dispatch(setRefreshed())
                    window.location.reload();
                }
            })
        })
    }

    return (
        <div className="modal_container">
            <div onClick={() => dispatch(modalOverlayFunc())} className='overlay'></div>
            <div className="branch_create_modal_card">
                <button onClick={() => dispatch(modalCloseFunc())} className='modal_close_btn'>Close</button>
                <div className="branch_update_modal_container">
                    <input value={examsTimeUpdateValue} onChange={(e) => dispatch(examsTimeUpdateFunc(e.target.value))} type="time" />
                    <input value={examsDateUpdateValue} onChange={(e) => dispatch(examsDateUpdateFunc(e.target.value))} type="date" />


                    <button className='exams_complated_button' onClick={examsDateTimeUpdateHandle}>İmtahan vaxtını dəyişdir</button>


                </div>

            </div>

        </div>
    )
}

export default ExamsDateTimeUpdateModal