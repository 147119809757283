import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { examsDateTimeModalFunc, examsDateTimeUpdateModalFunc } from '../redux/MainReducer'
import { examStartFunc, getPaymentList } from '../actions/MainAction'

const DashboardMainCard = ({ data }) => {
    const dispatch = useDispatch()
    const [isBuying, setIsBuying] = useState(false)

    useEffect(() => {
        dispatch(getPaymentList())
    }, [dispatch])

    const { paymentList, paymentUrl,orderId } = useSelector(state => state.Data)
    const currentUser = localStorage.getItem("onlineExamUser")

    const matchingPayment = paymentList.find(payment =>
        payment.exam_type === data?.id && payment.user === currentUser && payment?.status === "APPROVED"
    )

    const examBuy = (data) => {
        console.log("İmtahan alınır...", data)
        const examData = {
            amount: data?.amount,
            currency: "AZN",
            description: "Buy Exam",
            callbackUrl: "https://api.strategiyapanda.az/api/payment/callback",
            operation: "PURCHASE",
            examtype_id: data?.id
        }

        setIsBuying(true) // Alış prosesinin başladığını qeyd edirik
        dispatch(examStartFunc(examData))
    }

    useEffect(() => {
        if (isBuying && paymentUrl) {
            console.log("Ödəniş səhifəsinə yönləndirilir:", paymentUrl)
            window.location.href = paymentUrl
        }
    }, [paymentUrl, isBuying])

    return (
        <div className="exams_card">
            <div className="exams_card_content">
                <p>Exam Type: <span>{data.name}</span></p>
                <p>Variant: <span className='exams_card_content_variant'>{data.variant}</span></p>
                <p>Amount: <span className='exams_card_content_variant'>{data.amount}</span></p>
                <div className="exams_card_buttons">
                    {matchingPayment ? (
                        <button onClick={() => dispatch(data.is_booked? examsDateTimeUpdateModalFunc(data): examsDateTimeModalFunc(data))}>
                            Tarix və vaxtı {data.is_booked ? "dəyiş" : "təyin edin"}
                        </button>
                    ) : (
                        <button onClick={() => examBuy(data)} disabled={isBuying}>
                            {isBuying ? "Yönləndirilir..." : "İmtahanı al"}
                        </button>
                    )}
                </div>
            </div>
        </div>
    )
}

export default DashboardMainCard
